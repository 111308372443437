
import { defaultReconnectPolicy, SignalRClient } from '@/core/signalr/signalr.client';
import { LogLevel } from '@microsoft/signalr';
import { BusinessEntitySettingsViewObject, CustomerViewObject, DeliveryDeviationTypeViewObject, DeliveryTaskMarkStepNotified, DeliveryTaskStepOrder, DeliveryTaskViewObject, GeoLocation, GroupChatMessageViewObject, GroupChatViewObject, NotificationViewObject, TrackLocationRequest } from '@/api';
import bus from '@/core/bus';
import { getPlatform } from '@/infrastructure/environment';

const SIGNALR_URL = import.meta.env.VITE_API_BASE + '/hub';
const SIGNALR_LOGLEVEL = LogLevel.Information; // isDevMode() ? LogLevel.Information : LogLevel.Error;
const isRunningInBrowser = getPlatform() === 'web';

export class ServerHub extends SignalRClient {
    constructor() {
        super(SIGNALR_URL, isRunningInBrowser ? 'Web' : 'App', SIGNALR_LOGLEVEL, defaultReconnectPolicy, false);

        this.addEvent('TaskUpdated', (task: DeliveryTaskViewObject) => {
            bus.emit('TaskUpdated', task);
        });

        this.addEvent('TasksUpdated', (tasks: DeliveryTaskViewObject[]) => {
            bus.emit('TasksUpdated', tasks);
        });

        this.addEvent('TaskDeleted', (taskId: string) => {
            bus.emit('TaskDeleted', taskId);
        });

        this.addEvent('TasksDeleted', (taskIds: string[]) => {
            bus.emit('TasksDeleted', taskIds);
        });

        this.addEvent('MarkTaskStepsAsNotified', (data: DeliveryTaskMarkStepNotified[]) => {
            bus.emit('MarkTaskStepsAsNotified', data);
        });

        this.addEvent('CustomerRemoved', (customer: CustomerViewObject) => {
            bus.emit('CustomerRemoved', customer);
        });

        this.addEvent('UpdateSchedule', () => {
            bus.emit('UpdateSchedule');
        });

        this.addEvent('BusinessEntitySettingsUpdated', (settings: BusinessEntitySettingsViewObject) => {
            bus.emit('BusinessEntitySettingsUpdated', settings);
        });

        // CHAT
        this.addEvent('ChatMessageReceived', (message: GroupChatMessageViewObject) => {
            bus.emit('ChatMessageReceived', message);
        });

        this.addEvent('GroupChatCreatedOrUpdated', (chat: GroupChatViewObject) => {
            bus.emit('GroupChatCreatedOrUpdated', chat);
        });

        this.addEvent('GroupChatRemoved', (chatId: string) => {
            bus.emit('GroupChatRemoved', chatId);
        });

        // DELIVERY DEVIATION TYPES
        this.addEvent('DeliveryDeviationTypeCreated', (deviationType: DeliveryDeviationTypeViewObject) => {
            bus.emit('DeliveryDeviationTypeCreated', deviationType);
        });
                
        this.addEvent('DeliveryDeviationTypeUpdated', (deviationType: DeliveryDeviationTypeViewObject) => {
            bus.emit('DeliveryDeviationTypeUpdated', deviationType);
        });
                
        this.addEvent('DeliveryDeviationTypeRemoved', (deviationType: DeliveryDeviationTypeViewObject) => {
            bus.emit('DeliveryDeviationTypeRemoved', deviationType);
        });

        // NOTIFICATIONS
        this.addEvent('NotificationCreated', (notification: NotificationViewObject) => {
            bus.emit('NotificationCreated', notification);
        });

        this.addEvent('NotificationUpdated', (notification: NotificationViewObject) => {
            bus.emit('NotificationUpdated', notification);
        });

        this.addEvent('NotificationsUpdated', (notifications: NotificationViewObject[]) => {
            bus.emit('NotificationsUpdated', notifications);
        });
                
        this.addEvent('NotificationDeleted', (notificationId: string) => {
            bus.emit('NotificationDeleted', notificationId);
        });

        // TASKS
        this.addEvent('TasksOrderUpdated', (data: DeliveryTaskStepOrder[]) => {
            bus.emit('TasksOrderUpdated', data);
        });
    }

    public async activityUpdate(activity: string): Promise<void> {
        await this.connection.send('ActivityUpdate', activity);
    }

    public async trackLocation(location: GeoLocation | null | undefined, scheduleEntryId: string | null | undefined, driverLogId: string | null | undefined): Promise<void> {
        await this.connection.send('TrackLocation', {
            latitude: location?.latitude, 
            longitude: location?.longitude, 
            accuracy: location?.accuracy,
            altitude: location?.altitude,
            altitudeAccuracy: location?.altitudeAccuracy,
            heading: location?.heading,
            speed: location?.speed,
            scheduleEntryId: scheduleEntryId,
            driverLogId: driverLogId,
        } satisfies TrackLocationRequest);
    }
    
    public async toggleIsTyping(chatId: string | null | undefined, members: string[]): Promise<void> {
        await this.connection.send('ToggleIsTyping', chatId, members);
    }
}

export default new ServerHub();
