import { AddressViewObject } from '@/api';
import { parse, isValid } from 'date-fns';
import { getCountryCallingCode, getCountries, CountryCode, CountryCallingCode } from 'libphonenumber-js';
import { isEqual, mergeWith, camelCase, merge } from 'lodash-es';
import { CSSProperties } from 'vue';

export const ValidImageFiles = ['.jpg', '.jpeg', '.png', 'gif', '.tga', '.svg'];
export const ImageAccept = '.jpg,.jpeg,.png,.gif,.tga,.svg';

export enum TimeConstants {
    MINUTE = 60,
    HOUR = MINUTE * 60,
    DAY = HOUR * 24,
    WEEK = DAY * 7,
    MONTH = WEEK * 4,
    YEAR = WEEK * 52,
    IN_MILLISECONDS = 1000,
}

// eslint-disable-next-line @typescript-eslint/ban-types
type NonFunctional<T> = T extends Function ? never : T;

/**
 * Helper to produce an array of enum values.
 * @param enumeration Enumeration object.
 */
export function enumToArray<T extends object>(enumeration: T): NonFunctional<T[keyof T]>[] {
    return Object.keys(enumeration)
        .filter(key => isNaN(Number(key)))
        .map(key => enumeration[key] as NonFunctional<T[keyof T]>)
        .filter(val => typeof val === 'number' || typeof val === 'string') as NonFunctional<T[keyof T]>[];
}

export enum CustomHeaders {
    Token = 'x-token',
    TokenExpired = 'x-token-expired',
    LanguageReplaced = 'x-language-replaced'
}

export enum CustomRequestHeaders {
    SignalRConnectionId = 'x-connection-id',
    Culture = 'x-culture',
    Timezone = 'x-timezone',
    Language = 'x-language',
    Domain = 'x-domain',
    BusinessEntityId = 'x-businessentity-id',
    Channel = 'x-channel',
    AppVersionId = 'x-app-version-id'
}

export function parseJwt(token: string): { WebsiteVersion: string } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function getObjectDifference(oldObj: Record<string, unknown>, newObj: Record<string, unknown>) {
    return mergeWith(oldObj, newObj, function(objectValue, sourceValue, key, object, source) {
        if (!(isEqual(objectValue, sourceValue)) && (Object(objectValue) !== objectValue)) {
            console.log(object, key + '\n    Expected: ' + sourceValue + '\n    Actual: ' + objectValue);
        }
    });
}

export function addressEquals(a?: AddressViewObject | null, b?: AddressViewObject | null) {
    if (!a || !b) return false;
    
    return a.country.toLowerCase() === b.country.toLowerCase() &&
           a.city.toLowerCase() === b.city.toLowerCase() &&
           a.street.toLowerCase() === b.street.toLowerCase() &&
           a.postalCode === b.postalCode &&
           a.companyName.toLowerCase() === b.companyName.toLowerCase();
}

export const getPropertyValue = (obj: Object | null, propertyPath: string) => {
    for (const path of propertyPath.split('.'))
    {
        const camelCased = camelCase(path);
        const value = obj?.[camelCased];
        if (value == null)
            return null;

        obj = value;
    }

    return obj;
};

export function getTextHeightAndWidth(text: string, style: CSSProperties) {
    const span = document.createElement('span');
    document.body.appendChild(span);
  
    merge(span.style, style);
    span.style.position = 'fixed';
    span.style.top = '-1000px';
    span.style.left = '-1000px';
    span.style.whiteSpace = 'no-wrap';
    span.innerHTML = text;
  
    const width = Math.ceil(span.clientWidth);
    const height = Math.ceil(span.clientHeight);
  
    document.body.removeChild(span);

    return { width, height};
}

export function arraySwap<T = any>(array: T[], i: number, j: number) { [array[i], array[j]] = [array[j], array[i]]; }

export function dateIntervalsOverlap(dateStartA: Date, dateEndA: Date, dateStartB: Date, dateEndB: Date) {
    return dateStartA < dateEndB && dateStartB < dateEndA;
}

export function dateIntervalIsWithinInterval(dateStartA: Date, dateEndA: Date, dateStartB: Date, dateEndB: Date) {
    return dateStartA >= dateStartB && dateEndA <= dateEndB;
}

export function isBetweenTwoDates(date: Date | string, start: Date | string, end?: Date | string) {
    if (typeof date === 'string') 
        date = new Date(date);
    if (typeof start === 'string') 
        start = new Date(start);
    if (typeof end === 'string') 
        end = new Date(end);

    return date >= start && (!end || date <= end);
}

export function isSameDate(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

export type NestedObjectPaths<T> =
    T extends Array<infer U> ? { [K in Exclude<keyof U, symbol>]: `${K}${DotPrefix<NestedObjectPaths<U[K]>>}` }[Exclude<keyof U, symbol>] :
    (T extends object ?
        { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<NestedObjectPaths<T[K]>>}` }[Exclude<keyof T, symbol>]
        : '') extends infer D ? Extract<D, string> : never;

export function setValue<T>(obj: T, path: NestedObjectPaths<T>, value: any) {
    return (path as string).split('.').reduce((acc, cur, idx, arr) => (idx === arr.length - 1 ? acc[cur] = value : acc[cur] = acc[cur] || {}), obj);
}

export function getValue<T>(obj: T, path: NestedObjectPaths<T>) {
    return (path as string).split('.').reduce((acc, cur) => acc ? acc[cur] : undefined, obj);
}

export const tryParseDate = (dateStr: string | Date | null | undefined): Date | null => {
    if (!dateStr)
        return null;
    
    if (typeof dateStr === 'object' && isValid(dateStr))
        return dateStr;

    const date = new Date(dateStr);
    if (isValid(date))
        return date;

    const formats = [
        'dd.MM.yyyy', 'dd.MM.yyyy HH:mm', 
        'dd/MM/yyyy', 'dd/MM/yyyy HH:mm',
        'dd-MM-yyyy', 'dd-MM-yyyy HH:mm', 
        'yyyy-MM-dd', 'yyyy-MM-dd HH:mm',
        'MM/dd/yyyy', 'MM/dd/yyyy HH:mm', 
        'MM-dd-yyyy', 'MM-dd-yyyy HH:mm',
        'yyyy/MM/dd', 'yyyy/MM/dd HH:mm', 
        'yyyyMMdd', 'yyyyMMddHHmm',
    ];

    for (const dateFormat of formats) {
        const parsedDate = parse(dateStr as string, dateFormat, new Date());

        if (isValid(parsedDate)) {
            return parsedDate;
        }
    }

    return null;
};

export function setPropertiesToNull(obj: object) {
    if (obj !== null && typeof obj === 'object') {
        Object.keys(obj).forEach(key => {
            if (Array.isArray(obj[key])) {
                obj[key].forEach(item => setPropertiesToNull(item));
            } else {
                obj[key] = obj[key] !== null && typeof obj[key] === 'object' ? setPropertiesToNull(obj[key]) : null;
            }
        });
    }
    
    return obj;
}

export function createURLWithParams(baseURL: string, params: Record<string, string | number | undefined>) {
    const url = new URL(baseURL);
    Object.keys(params).forEach(key => {
        if (params[key] || params[key] === 0) { // Checks if the parameter is truthy, or explicitly zero
            url.searchParams.append(key, params[key] as string);
        }
    });
    return url.toString();
}

const countryCodes: readonly CountryCode[] = Object.freeze(getCountries().slice(1));

export function getCountryNames(): string[] {
    const regionNames = new Intl.DisplayNames([window.languageCode], { type: 'region' });
    const countryNames = countryCodes.map(code => regionNames.of(code)).filter(x => x).sort((a, b) => a!.localeCompare(b!, window.languageCode));
    return countryNames as string[];
}

export type CountryNameAndCodes = {
    code: CountryCode;
    name: string;
    callingCode: CountryCallingCode;
};

export function getCountryNamesAndCodes(): CountryNameAndCodes[] {
    const regionNames = new Intl.DisplayNames([window.languageCode], { type: 'region' });
    const countries = countryCodes.map(code => {
        const name = regionNames.of(code)!;
        const callingCode = getCountryCallingCode(code);
        return { code, name, callingCode };
    })
        .filter(x => x.name && x.callingCode) // Ensure both name and calling code are available
        .sort((a, b) => a.name.localeCompare(b.name, window.languageCode));

    return countries;
}

export function generateHexColors(count: number): string[] {
    const colors: string[] = [];
    for (let i = 0; i < count; i++) {
        // Generate a random color
        const color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
        colors.push(color);
    }
    return colors;
}

function hslToHex(h: number, s: number, l: number): string {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c / 2;
    let r = 0, g = 0, b = 0;

    if (h >= 0 && h < 60) { r = c; g = x; b = 0; }
    else if (h >= 60 && h < 120) { r = x; g = c; b = 0; }
    else if (h >= 120 && h < 180) { r = 0; g = c; b = x; }
    else if (h >= 180 && h < 240) { r = 0; g = x; b = c; }
    else if (h >= 240 && h < 300) { r = x; g = 0; b = c; }
    else if (h >= 300 && h < 360) { r = c; g = 0; b = x; }

    const toHex = (n: number) => Math.round((n + m) * 255).toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function generateUniqueHexColors(count: number): string[] {
    const colors: string[] = [];
    for (let i = 0; i < count; i++) {
        // Evenly distribute hues in the HSL color space
        const hue = (360 / count) * i; // Spread hues evenly around the color wheel
        const saturation = 70; // Keep saturation constant for vivid colors
        const lightness = 50; // Keep lightness constant for balanced colors

        // Convert HSL to hex
        colors.push(hslToHex(hue, saturation, lightness));
    }
    return colors;
}