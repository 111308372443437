import { App, defineAsyncComponent } from 'vue';

const CallToAction = defineAsyncComponent(() => import('./buttons/CallToAction.vue'));
const ToggleButton = defineAsyncComponent(() => import('./buttons/ToggleButton.vue'));
const Collapsible = defineAsyncComponent(() => import('./generic/Collapsible.vue'));
const Spinner = defineAsyncComponent(() => import('./loading-spinners/Spinner.vue'));
const SmartParagraph = defineAsyncComponent(() => import('./generic/SmartParagraph.vue'));
const UserInfo = defineAsyncComponent(() => import('./user/UserInfo.vue'));

export default function config(app: App): void {
    app.component('CallToAction', CallToAction);
    app.component('ToggleButton', ToggleButton);
    app.component('Collapsible', Collapsible);
    app.component('Spinner', Spinner);
    app.component('SmartParagraph', SmartParagraph);
    app.component('UserInfo', UserInfo);
}
