import { BusinessEntityUserDriverLogViewObject, DriverLogPreStartCheckEntry, ScheduleEntryViewObject } from '@/api';
import { computed, ComputedRef, defineAsyncComponent, WritableComputedRef } from 'vue';
import { Store } from '@/core/store/store';
import { userStore } from '@/store/user/user.store';
import taskApiService from '@/api-controllers/tasksApi.service';
import notificationsService from '@/core/notifications/notifications.service';
import { mergeObject } from '@/core/util/mergeObjects';
import bus from '@/core/bus';
import businessEntityApiService from '@/api-controllers/businessEntityApi.service';
import scheduleApiService from '@/api-controllers/scheduleApi.service';
import { differenceInHours, endOfDay, startOfDay, subMinutes } from 'date-fns';
import { isBetweenTwoDates, isSameDate } from '@/core/constants';
import dialogService from '@/core/dialog/dialog.service';
import dictionary from '@/core/dictionary/dictionary';

interface State extends Record<string, unknown> {
    scheduleDate: Date;
    schedules: ScheduleEntryViewObject[];
    schedulesMap: Record<string, ScheduleEntryViewObject | undefined>;
    driverLog?: BusinessEntityUserDriverLogViewObject;
    loading: boolean;
}

type Hooks = '';

class ScheduleStore extends Store<State, Hooks> {
    constructor() {
        super();

        bus.on('LOGGED_OUT', () => this.clear());
        bus.on('UpdateSchedule', () => this.getSchedules());
    }

    protected data() {
        return {
            scheduleDate: startOfDay(new Date()),
            schedules: [],
            schedulesMap: {},
            driverLog: undefined,
            loading: false,
        };
    }

    public get scheduleDate(): WritableComputedRef<Date> {
        return computed({
            get: () => this.state.scheduleDate,
            set: (v) => {
                this.state.scheduleDate = startOfDay(v);
                this.getSchedules();
            },
        });
    }

    public get schedules(): WritableComputedRef<ScheduleEntryViewObject[]> {
        return computed({
            get: () => this.state.schedules,
            set: (v) => {
                this.state.schedules = v;
                this.state.schedulesMap = this.state.schedules.reduce((a, b) => {
                    a[b.id] = b;
                    return a;
                }, {});
            },
        });
    }

    public get schedulesMap(): ComputedRef<Record<string, ScheduleEntryViewObject | undefined>> {
        return computed(() => this.state.schedulesMap);
    }

    public getActiveSchedule(): ScheduleEntryViewObject | undefined {
        let earlyStartMinutes = userStore.user.value?.businessEntity.settings.deliverySettings.earlyScheduleStartMinutes ?? 0;
        if (earlyStartMinutes < 0)
            earlyStartMinutes = 0;

        return this.state.schedules.find(x => {
            const now = new Date();
            const startDate = subMinutes(new Date(x.startDate), earlyStartMinutes);
            const endDate = new Date(x.endDate);
            return isBetweenTwoDates(now, startDate, endDate);
        });
    }

    public get isLoading(): WritableComputedRef<boolean> {
        return computed({
            get: () => this.state.loading,
            set: (v) => this.state.loading = v,
        });
    }
    
    public get driverLog(): ComputedRef<BusinessEntityUserDriverLogViewObject | undefined> {
        return computed(() => this.state.driverLog);
    }

    public async getSchedules() {
        if (!userStore.isLoggedIn.value) 
            return;

        const result = await scheduleApiService.getSchedulesForDate(startOfDay(this.scheduleDate.value), endOfDay(this.scheduleDate.value));
        if (result) {
            this.schedules.value = result.entries;
            this.state.schedulesMap = this.state.schedules.reduce((a, b) => {
                a[b.id] = b;
                return a;
            }, {});
        }
    }

    public async getActiveDriverLog() {
        if (this.state.loading || !userStore.isLoggedIn.value) 
            return;

        this.state.loading = true;

        const result = await taskApiService.getActiveDriverLog();
        if (result) {
            this.setActiveDriverLog(result);
        }

        this.state.loading = false;
    }

    public async startDriverLog() {
        if (this.state.loading || !userStore.isLoggedIn.value) 
            return;
        
        if (this.state.driverLog?.inProgress) {
            notificationsService.notify({
                title: 'Du har allerede en igangværende kørsels log',
            });
            return;
        }

        const now = new Date();
        if (!isSameDate(this.state.scheduleDate, now)) {
            const accepted = await dialogService.dialogConfirmation({
                title: 'Du er ved at starte en chauffør log for en anden dato end dags dato, hvilket ikke er muligt.\nVil du starte en ny chauffør log for i dag?',
            });
    
            if (!accepted)
                return;
            
            this.state.scheduleDate = startOfDay(now);
            await this.getSchedules();
        }

        const activeSchedule = this.getActiveSchedule();

        if (!activeSchedule) {
            notificationsService.notify({
                title: 'Du er ikke booket til at kører lige nu',
                type: 'Warning',
            });
            return;
        }

        let preStartCheckEntries: DriverLogPreStartCheckEntry[] | undefined = [];

        const resourceId = activeSchedule.resourceId;
        if (resourceId) {
            const preStartChecksResult = await businessEntityApiService.getUserDriverLogPreStartChecks(resourceId);
            if (preStartChecksResult?.preStartChecks?.length) {
                preStartCheckEntries = await dialogService.showModal<DriverLogPreStartCheckEntry[]>({
                    component: defineAsyncComponent({ loader: () => import('@/components/schedule/PreStartChecks.vue') }),
                    componentAttrs: {
                        checks: preStartChecksResult.preStartChecks,
                    },
                }, {
                    size: 'sm',
                });

                if (!preStartCheckEntries)
                    return;
            }
        }
        
        this.state.loading = true;

        const result = await taskApiService.startDriverLog({ location: userStore.getLocation(), scheduleId: activeSchedule.id, preStartCheckEntries });
        if (result) {
            this.setActiveDriverLog(result);
        }

        this.state.loading = false;
    }

    public async completeDriverLog() {
        if (this.state.loading || !userStore.isLoggedIn.value) 
            return;
        
        if (!this.state.driverLog?.inProgress) {
            notificationsService.notify({
                title: 'Du har ikke nogen igangværende kørsels log',
            });
            return;
        }
        
        this.state.loading = true;

        const result = await taskApiService.completeDriverLog({ location: userStore.getLocation() });
        if (result) {
            this.setActiveDriverLog(result);
        }

        this.state.loading = false;
    }

    public async startBreak(description: string | null) {
        if (this.state.loading || !userStore.isLoggedIn.value) 
            return;
        
        if (!this.state.driverLog?.inProgress) {
            notificationsService.notify({
                title: 'Du har ikke en igangværende kørsels log',
            });
            return;
        }

        if (this.state.driverLog?.isOnBreak) {
            notificationsService.notify({
                title: 'Du har allerede igangværende pause',
            });
            return;
        }
        
        this.state.loading = true;

        const result = await taskApiService.startBreak({ location: userStore.getLocation(), description: description });
        if (result) {
            this.setActiveDriverLog(result);
        }

        this.state.loading = false;
    }

    public async stopBreak() {
        if (this.state.loading || !userStore.isLoggedIn.value) 
            return;
        
        if (!this.state.driverLog?.inProgress) {
            notificationsService.notify({
                title: 'Du har ikke en igangværende kørsels log',
            });
            return;
        }

        if (!this.state.driverLog?.isOnBreak) {
            notificationsService.notify({
                title: 'Du er ikke ved at holde pause',
            });
            return;
        }
        
        this.state.loading = true;

        const result = await taskApiService.stopBreak({ location: userStore.getLocation() });
        if (result) {
            this.setActiveDriverLog(result);
        }

        this.state.loading = false;
    }

    private async setActiveDriverLog(driverLog: BusinessEntityUserDriverLogViewObject) {
        const driverLogDurationWarningHours = userStore.user.value.businessEntity.settings.deliverySettings.driverLogDurationHoursWarning;
        if (driverLog.duration.startDateTime && driverLogDurationWarningHours > 0) {
            const now = new Date();
            const driverLogStartedDate = new Date(driverLog.duration.startDateTime);
            const diff = differenceInHours(now, driverLogStartedDate);
            if (diff >= driverLogDurationWarningHours) {
                const accepted = await dialogService.dialogConfirmation({
                    title: dictionary.get('DriverLog.DurationWarning', driverLogDurationWarningHours),
                });
                if (accepted) {
                    await taskApiService.completeDriverLog({ location: userStore.getLocation() });
                    await this.startDriverLog();
                    return;
                }
            }
        }

        if (this.state.driverLog?.id === driverLog.id) {
            mergeObject(this.state.driverLog, driverLog);
        } else {
            this.state.driverLog = driverLog;
        }
    }

    public clearActiveDriverLog() {
        this.state.driverLog = undefined;
    }

    public clear() {
        this.state.driverLog = undefined;
        this.state.schedules = [];
        this.state.schedulesMap = {};
        this.state.loading = false;
    }
}

export const scheduleStore: ScheduleStore = (((window as any)).__scheduleStore ??= new ScheduleStore());
