import { DeliveryServerContextViewObject } from '@/api';
import { AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

declare global {
    interface Window {
        domainName?: string | null;
        appInfo?: AppInfo | null;
        serverContext?: DeliveryServerContextViewObject
    }
}

declare global {
    interface ImportMetaEnv {
        readonly VITE_API_BASE: string
    }
}

export function isDevMode(): boolean {
    return !isProdMode();
}

export function isProdMode(): boolean {
    // return process.env.NODE_ENV === 'production';
    return import.meta.env.PROD;
}

export function getPlatform(): 'android' | 'ios' | 'web' {
    return Capacitor.getPlatform() as 'android' | 'ios' | 'web';
}

export const isRunningInBrowser = getPlatform() === 'web';