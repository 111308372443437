import { ChangePasswordRequest, LoginRequest, LoginResponse, RefreshSessionResponse, RequestPasswordResetRequest, ResetPasswordRequest } from '@/api';
import { ApiBase } from '@/core/api/api.base';
import { apiInstanceFactory } from '@/core/api/api.factory';

const api = apiInstanceFactory();

class AuthService extends ApiBase {
    public async login(request: LoginRequest): Promise<LoginResponse | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authLoginCreate(request));
    }

    public async logout(): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authLogoutCreate(), {
            suppressErrors: true,
        });
    }

    public async refreshSession(): Promise<RefreshSessionResponse | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authRefreshSessionList(), {
            retries: 3,
        });
    }

    public async requestPasswordReset(request: RequestPasswordResetRequest): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authRequestPasswordResetCreate(request));
    }

    public async resetPassword(request: ResetPasswordRequest): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authResetPasswordCreate(request));
    }
    
    public async changePassword(request: ChangePasswordRequest): Promise<RefreshSessionResponse | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authChangePasswordCreate(request));
    }
}

export default new AuthService();
