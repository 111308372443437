import { BusinessEntitySettingsViewObject, BusinessEntityUserRole, GeoLocation, TrackLocationRequest } from '@/api';
import { CustomHeaders, enumToArray } from '@/core/constants';
import { LocalStorageService } from '@/core/storage/storage.service';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import { computed, ComputedRef } from 'vue';
import { UserStore } from '@/core/store/user/user.store';
import { Geolocation, Position } from '@capacitor/geolocation';
import { getPlatform } from '@/infrastructure/environment';
import { CapacitorHttp } from '@capacitor/core';
import { scheduleStore } from '../schedule/schedule.store';
import { registerPlugin } from '@capacitor/core';
import {BackgroundGeolocationPlugin} from '@capacitor-community/background-geolocation';
import { RouteNames } from '@/router/router.routes';
import { Dialog } from '@capacitor/dialog';
import serverHubClient from '@/signalR/serverHub.client';
import dialogService from '@/core/dialog/dialog.service';
import dictionary from '@/core/dictionary/dictionary';
import bus from '@/core/bus';
import HttpStatus from 'http-status-codes';
import router from '@/router/router.index';

interface State extends Record<string, unknown> {
    tracking: {
        enabled: boolean;
        watchId?: string | null;
        error?: string | null;
        position?: Position | null;
    }
}
  
const roleArray = enumToArray(BusinessEntityUserRole);
const isRunningInBrowser = getPlatform() === 'web';
const BackgroundGeolocation: BackgroundGeolocationPlugin | undefined = !isRunningInBrowser ? registerPlugin<BackgroundGeolocationPlugin>('BackgroundGeolocation') : undefined;

function redirectToStore() {
    let storeUrl: string | undefined;
  
    if (getPlatform() === 'android') {
        storeUrl = 'https://play.google.com/store/apps/details?id=delivery.imendo.dk';
    } else if (getPlatform() === 'ios') {
        storeUrl = 'https://apps.apple.com/app/id/delivery.imendo.dk';
    }
  
    if (storeUrl) {
        window.open(storeUrl, '_system', 'location=yes');
    }
}
  
class BusinessUserStore extends UserStore<State> {
    constructor() {
        super(true);

        bus.on('BusinessEntitySettingsUpdated', (settings: BusinessEntitySettingsViewObject) => {
            if (this.state.identity.user) 
                this.state.identity.user.businessEntity.settings = settings;
        });

        serverHubClient.addEvent('ValidateVersion', (version: string, appVersionId?: string) => {
            if (!isRunningInBrowser && appVersionId && window.appInfo && window.appInfo.version < appVersionId) {
                Dialog.alert({
                    title: 'Update Available',
                    message: dictionary.get('AppVersion.UpdateAvailable', window.appInfo.version, appVersionId),
                    buttonTitle: 'Update Now',
                }).then(() => {
                    redirectToStore();
                });
            }
        });

        bus.on('LOGGED_OUT', () => this.clearLocationWatch());
    }

    protected override data() {
        return {
            identity: {
                user: undefined,
                token: LocalStorageService.getItem(LocalStorageKeys.TOKEN),
            },
            tracking: {
                enabled: false,
            },
        };
    }

    private async checkPermissions(checkForIntent: boolean) {
        if (!this.state.identity.user) return;

        let intentDeclarationAccepted = LocalStorageService.getBool(LocalStorageKeys.HAS_ACCEPTED_GEOLOCATION_INTENT_DECLARATION);

        if (typeof intentDeclarationAccepted !== 'boolean' || (checkForIntent && !intentDeclarationAccepted)) {
            intentDeclarationAccepted = await dialogService.dialogConfirmation({ 
                title: dictionary.get('GeoLocationTracking.IntentDeclaration'), 
                okText: dictionary.get('Generic.Accept'), 
                cancelText: dictionary.get('Generic.Decline'),
            }, { size: 'md'});
        }

        if (intentDeclarationAccepted) {
            if (isRunningInBrowser) { // Permissions checking is not available on web
                this.state.tracking.error = null;
                this.state.tracking.enabled = true;
            } else {
                try {
                    const hasPermission = await Geolocation.checkPermissions();
                    if (hasPermission.location !== 'granted') {
                        const result = await Geolocation.requestPermissions({
                            permissions: ['location'],
                        });
                        this.state.tracking.enabled = result.location === 'granted';
                    } else {
                        this.state.tracking.enabled = hasPermission.location === 'granted';
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        } else {
            this.state.tracking.error = 'Tilladelse til at tracke lokations data ikke givet';
            this.state.tracking.enabled = false;
        }

        LocalStorageService.setItem(LocalStorageKeys.HAS_ACCEPTED_GEOLOCATION_INTENT_DECLARATION, intentDeclarationAccepted);
    }

    private async trackLocationNatively() {
        const location = this.state.tracking.position?.coords;
        const activeSchedule = scheduleStore.getActiveSchedule();

        const response = await CapacitorHttp.post({ 
            url: 'https://delivery.imendo.dk/api/User/TrackLocation',
            headers: {
                Authorization: `Bearer ${LocalStorageService.getItem(LocalStorageKeys.TOKEN)}`,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                accuracy: location?.accuracy,
                altitude: location?.altitude,
                altitudeAccuracy: location?.altitudeAccuracy,
                heading: location?.heading,
                latitude: location?.latitude,
                longitude: location?.longitude,
                speed: location?.speed,
                scheduleEntryId: activeSchedule?.id,
                driverLogId: scheduleStore.driverLog.value?.id,
            } satisfies TrackLocationRequest),
        });

        return response;
    }

    private lastTrackedAtUtcMS: number = 0;
    private minDurationMSBetweenLocationTracking: number = 3 * 1000; // 3 seconds in milliseconds

    private async trackLocation() {
        try {
            if (!this.state.tracking.position?.timestamp || (this.state.tracking.position.timestamp - this.lastTrackedAtUtcMS >= this.minDurationMSBetweenLocationTracking))
            {
                if (isRunningInBrowser) {
                    const activeSchedule = scheduleStore.getActiveSchedule();
                    await serverHubClient.trackLocation(this.state.tracking.position?.coords, activeSchedule?.id, scheduleStore.driverLog.value?.id);
                } else {
                    const response = await this.trackLocationNatively();

                    if (response) {
                        const isTokenInvalid = response?.headers[CustomHeaders.TokenExpired];
                        if (isTokenInvalid) {
                            await serverHubClient.stop();
                            userStore.clear();
                            LocalStorageService.removeItem(LocalStorageKeys.TOKEN);
                            bus.emit('LOGGED_OUT');
                            await router.push({ name: RouteNames.AUTH_LOGIN });
                            return;
                        }
                
                        const token = response?.headers[CustomHeaders.Token] as string;
                        if (token) {
                            LocalStorageService.setItem(LocalStorageKeys.TOKEN, token);
                        }
                
                        if (response.status === HttpStatus.UNAUTHORIZED) {
                            if (token) {
                                // We got a 401 but a new token has been provided, retry the rejected request
                                await this.trackLocationNatively();
                            } else {
                                await serverHubClient.stop();
                                userStore.clear();
                                LocalStorageService.removeItem(LocalStorageKeys.TOKEN);
                                bus.emit('LOGGED_OUT');
                                await router.push({ name: RouteNames.AUTH_LOGIN });
                                return;
                            }
                        }
                    }
                }

                this.lastTrackedAtUtcMS = new Date().getTime();
            }
        } catch (e) {
            console.error(e);
        }
    }
    
    public async watchLocation(checkForIntent: boolean) {
        if (!this.state.identity.user) return;

        await this.checkPermissions(checkForIntent);
      
        if (this.state.tracking.enabled) {
            if (this.state.tracking.watchId) {
                await this.clearLocationWatch();
            }

            if (isRunningInBrowser) {
                this.state.tracking.watchId = await Geolocation.watchPosition({ enableHighAccuracy: true }, async(position, err) => {
                    this.state.tracking.error = err;
                    this.state.tracking.position = position ? {
                        coords: position.coords,
                        timestamp: new Date().getTime(),
                    } : null;

                    await this.trackLocation();
                });
            } else {
                this.state.tracking.watchId = await BackgroundGeolocation!.addWatcher(
                    {
                        // If the "backgroundMessage" option is defined, the watcher will
                        // provide location updates whether the app is in the background or the
                        // foreground. If it is not defined, location updates are only
                        // guaranteed in the foreground. This is true on both platforms.
                
                        // On Android, a notification must be shown to continue receiving
                        // location updates in the background. This option specifies the text of
                        // that notification.
                        backgroundMessage: dictionary.get('BackgroundTracking.Message'),
                
                        // The title of the notification mentioned above. Defaults to "Using
                        // your location".
                        backgroundTitle: dictionary.get('BackgroundTracking.Title'),
                
                        // Whether permissions should be requested from the user automatically,
                        // if they are not already granted. Defaults to "true".
                        requestPermissions: false,
                
                        // If "true", stale locations may be delivered while the device
                        // obtains a GPS fix. You are responsible for checking the "time"
                        // property. If "false", locations are guaranteed to be up to date.
                        // Defaults to "false".
                        stale: false,
                
                        // The minimum number of metres between subsequent locations. Defaults
                        // to 0.
                        distanceFilter: 20,
                    },
                    async(location, error) => {
                        this.state.tracking.error = error?.message;
                        this.state.tracking.position = location ? {
                            coords: {
                                accuracy: location.accuracy,
                                altitude: location.altitude,
                                altitudeAccuracy: location.altitudeAccuracy,
                                heading: location.bearing,
                                latitude: location.latitude,
                                longitude: location.longitude,
                                speed: location.speed,
                            },
                            timestamp: new Date().getTime(),
                        } : null;

                        await this.trackLocation();
                    },
                );
            }
        }
    }
      
    public async clearLocationWatch() {
        if (this.state.tracking.watchId) {
            if (isRunningInBrowser) {
                await Geolocation.clearWatch({ id: this.state.tracking.watchId });
            } else {
                await BackgroundGeolocation!.removeWatcher({
                    id: this.state.tracking.watchId,
                });
            }

            this.state.tracking.enabled = false;
            this.state.tracking.position = null;
            this.state.tracking.watchId = null;
            this.state.tracking.error = null;
            await this.trackLocation();
        }
    }

    public hasRole(role: BusinessEntityUserRole) {
        return !!this.state.identity.user && roleArray.indexOf(this.state.identity.user.role) >= roleArray.indexOf(role);
    }

    public get isAdministrator(): ComputedRef<boolean> {
        return computed(() => this.hasRole(BusinessEntityUserRole.Administrator));
    }

    public get isModerator(): ComputedRef<boolean> {
        return computed(() => this.hasRole(BusinessEntityUserRole.Moderator));
    }

    public get isCarrier(): ComputedRef<boolean> {
        return computed(() => this.hasRole(BusinessEntityUserRole.Carrier));
    }

    public get isLocationTrackingDisabled(): ComputedRef<boolean> {
        return computed(() => !this.state.tracking.enabled);
    }

    public get isLocationTrackingSupported(): ComputedRef<boolean> {
        return computed(() => true);
    }

    public get isLocationTrackingForced(): ComputedRef<boolean> {
        return computed(() => !!this.state.identity.user?.businessEntity.settings.deliverySettings.forceGeoLocationTracking);
    }

    private get coords() {
        return computed(() => this.state.tracking.position?.coords);
    }

    private get error() {
        return computed(() => this.state.tracking.error);
    }

    public get location(): ComputedRef<GeoLocation | null> {
        return computed(() => (this.error.value || !this.coords.value?.latitude || !this.coords.value?.longitude || this.isLocationTrackingDisabled.value) ? null : ({ 
            latitude: this.coords.value.latitude,
            longitude: this.coords.value.longitude,
            accuracy: this.coords.value.accuracy,
            altitude: this.coords.value.altitude,
            altitudeAccuracy: this.coords.value.altitudeAccuracy,
            heading: this.coords.value.heading,
            speed: this.coords.value.speed,
        } as GeoLocation));
    }

    public getLocation(): GeoLocation | null {
        return (this.error.value || !this.coords.value?.latitude || !this.coords.value?.longitude || this.isLocationTrackingDisabled.value) ? null : ({ 
            latitude: this.coords.value.latitude,
            longitude: this.coords.value.longitude,
            accuracy: this.coords.value.accuracy,
            altitude: this.coords.value.altitude,
            altitudeAccuracy: this.coords.value.altitudeAccuracy,
            heading: this.coords.value.heading,
            speed: this.coords.value.speed,
        } as GeoLocation);
    }
}

export const userStore: BusinessUserStore = (((window as any)).__userStore ??= new BusinessUserStore());

// https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-8.0&tabs=visual-studio#bsleep
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let lockResolver: any;
if (navigator && navigator.locks && navigator.locks.request) {
    const promise = new Promise((res) => {
        lockResolver = res;
    });

    navigator.locks.request('unique_lock_name', { mode: 'shared' }, () => {
        return promise;
    });
}