import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { BusinessEntityUserDriverLogPreStartCheckViewModel, BusinessEntityUserLocationTrackingLogViewModel, DeliveryDeviationTypeViewModel, DeliveryServerContextViewObject } from '@/api';

const api = apiInstanceFactory();

class BusinessEntityService extends ApiBase {
    public async isDomainValid(domain: string): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityIsDomainValidList({ domain }), {
            suppressErrors: true,
        });
    }

    public async getServerContext(): Promise<DeliveryServerContextViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetServerContextList());
    }

    public async getLocationTracking(driverLogId: string): Promise<BusinessEntityUserLocationTrackingLogViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetLocationTrackingList({
            driverLogId: driverLogId,
        }));
    }

    public async getDeliveryDeviations(): Promise<DeliveryDeviationTypeViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetDeliveryDeviationTypesList());
    }

    public async getUserDriverLogPreStartChecks(resourceId: string): Promise<BusinessEntityUserDriverLogPreStartCheckViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.businessEntityGetUserDriverLogPreStartChecksList({ resourceId }), { 
            suppressErrors: true,
        });
    }
}

export default new BusinessEntityService();
