import { createApp } from 'vue';
import App from './App.vue';
import { App as AppType } from 'vue';
import router from './router/router.index';
import { RouteNames } from './router/router.routes';
import { LocalStorageKeys } from './project/localStorageKeys';
import { getPlatform } from './infrastructure/environment';
import { App as CapacitorApp } from '@capacitor/app';

import './styling/main.scss';

import coreConfig from '@/core';
import projectConfig from '@/project';
import componentsConfig from '@/components';
import dictionary from '@/core/dictionary/dictionary';
import loadingScreen from '@/core/dom/loadingScreen';
import invalidServerContext from '@/core/dom/invalidServerContext';
import authenticationService from './core/authentication/authentication.service';
import businessEntityApiService from './api-controllers/businessEntityApi.service';
import dictionaryApiService from './api-controllers/dictionaryApi.service';

let app: AppType;

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $routes: typeof RouteNames;
    }
}

(async function() {
    loadingScreen.show();

    const isRunningInBrowser = getPlatform() === 'web';

    if (isRunningInBrowser) {
        window.domainName = window.location.pathname.split('/')[1];
    } else {
        window.appInfo = await CapacitorApp.getInfo();
        window.domainName = window.localStorage.getItem(LocalStorageKeys.DOMAIN);
    }

    if (window.domainName) {
        window.serverContext = (await businessEntityApiService.getServerContext())!;
        if (!window.serverContext) {
            loadingScreen.hide();
            invalidServerContext.show();
            return;
        }
    
        const result = await dictionaryApiService.getDictionaryLabelsForArea();
    
        if (result?.labels?.length) {
            dictionary.init(result.labels);
        }
            
        await authenticationService.tryRefreshSession();
    } else if (isRunningInBrowser) {
        loadingScreen.hide();
        invalidServerContext.show();
        return;
    }

    app = createApp(App);
    
    coreConfig(app);
    projectConfig(app);
    componentsConfig(app);
        
    app.config.globalProperties.$routes = RouteNames;
    
    app.use(router);
    
    await router.isReady();
    
    app.mount('#app');

    loadingScreen.hide();
})();

export { app };
