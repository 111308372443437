import { BusinessEntityUserRole } from '@/api';
import { startCase } from 'lodash-es';
import { RouteRecordRaw, RouteMeta, RouteLocationNormalized } from 'vue-router';
import { getPlatform } from '@/infrastructure/environment';

export interface RouteMetaExtended extends RouteMeta {
    allowAnonymous?: boolean;
    role?: BusinessEntityUserRole;
}

export enum RouteNames {
    AUTH = 'AUTH',
    AUTH_LOGIN = 'AUTH_LOGIN',
    AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD',
    AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
    HOME = 'HOME',
    DASHBOARD = 'DASHBOARD',
    HISTORY = 'HISTORY',
    HISTORY_DRIVER_LOGS = 'HISTORY_DRIVER_LOGS',
    NAVIGATION = 'NAVIGATION',
    SCHEDULE = 'SCHEDULE',
    SCHEDULE_OVERVIEW = 'SCHEDULE_OVERVIEW',
    SCHEDULE_DETAILS = 'SCHEDULE_DETAILS',
    USERPROFILE = 'USERPROFILE',
}

const isRunningInBrowser = getPlatform() === 'web';

const createAuthenticatedPath = (...paths: string[]) => {
    return isRunningInBrowser ? `/:businessEntity:/${paths.join('/')}` : `/${paths.join('/')}`;
};

export const routes: Array<RouteRecordRaw> = [
    {
        path: createAuthenticatedPath(''),
        name: RouteNames.HOME,
        component: () => import('@/pages/app/Index.vue'),
        redirect: {
            name: RouteNames.DASHBOARD,
        },
        children: [
            {
                path: createAuthenticatedPath('dashboard'),
                name: RouteNames.DASHBOARD,
                component: () => import('@/pages/app/dashboard/Dashboard.vue'),
                meta: {
                    allowAnonymous: false,
                } as RouteMetaExtended,
            },
            {
                path: createAuthenticatedPath('history'),
                name: RouteNames.HISTORY,
                component: () => import('@/pages/app/history/History.vue'),
                redirect: {
                    name: RouteNames.HISTORY_DRIVER_LOGS,
                },
                children: [
                    {
                        path: createAuthenticatedPath('history', 'driver-logs'),
                        name: RouteNames.HISTORY_DRIVER_LOGS,
                        component: () => import('@/pages/app/history/HistoryDriverLogOverview.vue'),
                        meta: {
                            allowAnonymous: false,
                        } as RouteMetaExtended,
                    },
                ],
            },
            {
                path: createAuthenticatedPath('navigation'),
                name: RouteNames.NAVIGATION,
                component: () => import('@/pages/app/Navigation.vue'),
                meta: {
                    allowAnonymous: false,
                } as RouteMetaExtended,
            },
            {
                path: createAuthenticatedPath('schedule'),
                name: RouteNames.SCHEDULE,
                component: () => import('@/pages/app/schedule/Schedule.vue'),
                redirect: { name: RouteNames.SCHEDULE_OVERVIEW },
                meta: {
                    allowAnonymous: false,
                } as RouteMetaExtended,
                children: [
                    {
                        path: createAuthenticatedPath('schedule', 'overview'),
                        name: RouteNames.SCHEDULE_OVERVIEW,
                        component: () => import('@/pages/app/schedule/ScheduleOverview.vue'),
                        meta: {
                            allowAnonymous: false,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('schedule', ':taskId', ':type', 'details'),
                        name: RouteNames.SCHEDULE_DETAILS,
                        component: () => import('@/pages/app/schedule/ScheduleDetails.vue'),
                        props: (route: RouteLocationNormalized) => ({
                            taskId: route.params.taskId.toString().toLowerCase(),
                            type: startCase(route.params.type.toString().toLowerCase()),
                        }),
                        meta: {
                            allowAnonymous: false,
                        } as RouteMetaExtended,
                    },
                ],
            },
            {
                path: createAuthenticatedPath('user-profile'),
                name: RouteNames.USERPROFILE,
                component: () => import('@/pages/app/UserProfile.vue'),
                
                meta: {
                    allowAnonymous: false,
                } as RouteMetaExtended,
            },
        ],
    },
    {
        path: createAuthenticatedPath('auth'),
        name: RouteNames.AUTH,
        component: () => import('@/pages/login/LoginPage.vue'),
        meta: {
            allowAnonymous: true,
        },
        children: [
            {
                path: createAuthenticatedPath('auth', 'login'),
                name: RouteNames.AUTH_LOGIN,
                component: () => import('@/pages/login/LoginForm.vue'),
                meta: {
                    allowAnonymous: true,
                },
            },
            {
                path: createAuthenticatedPath('auth', 'forgot-password'),
                name: RouteNames.AUTH_FORGOT_PASSWORD,
                component: () => import('@/pages/login/ForgotPasswordForm.vue'),
                meta: {
                    allowAnonymous: true,
                },
            },
            {
                path: createAuthenticatedPath('auth', 'reset-password'),
                name: RouteNames.AUTH_RESET_PASSWORD,
                component: () => import('@/pages/login/ResetPasswordForm.vue'),
                props: (route: RouteLocationNormalized) => ({
                    token: route.query.token,
                }),
                meta: {
                    allowAnonymous: true,
                },
            },
        ],
    },
    {
        path: '/:businessEntity:/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        meta: {
            allowAnonymous: true,
        },
    },
];
