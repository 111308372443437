<template>
    <div class="web__app__layout">
        <RouterView v-slot="{ Component }">
            <Transition
                mode="out-in"
                appear
                :enter-active-class="enterActiveClass"
                :leave-active-class="leaveActiveClass">
                <component :is="Component"/>
            </Transition>
        </RouterView>
    </div>

    <ModalHost/>
    <NotificationsHost/>
    <ProgressBar/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAnimation } from '@/core/animation/animateComposable';

import ModalHost from '@/core/dialog/ModalHost.vue';
import ProgressBar from '@/core/loading/ProgressBar.vue';
import NotificationsHost from '@/core/notifications/NotificationsHost.vue';

export default defineComponent({
    name: 'App',
    components: {
        ModalHost,
        ProgressBar,
        NotificationsHost,
    },
    setup() {
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });

        return {
            enterActiveClass,
            leaveActiveClass,
        };
    },
});
</script>

<style>
#app {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    background-color: theme('colors.background');

    height: 100%;
}
</style>

<style lang="scss" scoped>
.web__app__layout {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    height: 100%;
    width: 100vw;
    max-width: 1300px;

    margin: 0 auto;

    outline: 1px solid theme('colors.border');
}
</style>
