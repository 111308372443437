import dictionary from '@/core/dictionary/dictionary';
import notificationsService from '@/core/notifications/notifications.service';
import serverHubClient from '@/signalR/serverHub.client';
import { userStore } from '@/store/user/user.store';
import { createRouter, createWebHistory } from 'vue-router';
import { RouteNames, routes, RouteMetaExtended } from './router.routes';
import { isProdMode } from '@/infrastructure/environment';

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach(async(to, from, next) => {
    const meta = to.meta as RouteMetaExtended;
    const isLoggedIn = userStore.isLoggedIn;

    if ((to.name === RouteNames.AUTH_LOGIN || 
        to.name === RouteNames.AUTH_FORGOT_PASSWORD ||
        to.name === RouteNames.AUTH_RESET_PASSWORD) && isLoggedIn.value) {
        next({ name: RouteNames.DASHBOARD, params: to.params, query: to.query });
        return;
    } else if (!meta.allowAnonymous && !isLoggedIn.value) {
        next({ name: RouteNames.AUTH_LOGIN, params: to.params, query: { to: to.path, ...to.query } });
        return;
    } else if (meta.role && !userStore.hasRole(meta.role)) {
        notificationsService.notify({
            title: 'Ingen adgang',
            message: 'Du har ikke adgang til at tilgå denne side',
            type: 'Warning',
            duration: 10000,
        });

        next({ name: RouteNames.DASHBOARD, params: to.params });
        return;
    }

    next();
});

router.afterEach((to, from) => {
    // const meta = to.meta as RouteMetaExtended;

    const title = to.name?.toString();
    const { user } = userStore;

    if (title) {
        const userTitle = user.value ? (user.value.fullName.endsWith('-') ? user.value.fullName : `${user.value.fullName} -`) : '';
        const pageTitleKey = 'Page.Title.' + title;
        if (dictionary.has(pageTitleKey)) {
            const pageTitle = dictionary.get(pageTitleKey);
            document.title = `${pageTitle} - ${userTitle} IMendo`;
        } else {
            document.title = `${title} - ${userTitle} IMendo`;
        }
        
        userStore.isLoggedIn.value && serverHubClient.activityUpdate(title);
    } else {
        document.title = 'IMendo';
    }
});

const isProduction = isProdMode();
if (isProduction) {
    router.onError((e, to) => {
        if (e && typeof e.message === 'string') {
            if (e.message.startsWith('Failed to fetch dynamically imported module')) {
                // After a deployment, users must re-fresh the page to update the async components locations
                window.location.reload();
            }
        }
    });
}

console.log(`IMENDO APP ENVIRONMENT: ${isProduction ? 'PRODUCTION' : 'DEVELOPMENT'}`);

export default router;
